import React from 'react';
import { Button, Paper, Breadcrumbs, Typography, SnackbarContent, Snackbar } from '@material-ui/core';
import LoadingScreen from '../Utils/LoadingScreen';
import {ingorePacketsOlderThan} from '../resources/parameters.json';
import { yyyyMMddToddMMyyyy, formatDate, timestampToDate,getNearestDurfs, isInHoursBeforeDeparture, stopTimeToTodayDate} from '../Utils/utility';
import { fetchData, fetchFromOldService, fetchWay } from '../Utils/network';
import ErrorView from './ErrorView';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import 'react-day-picker/lib/style.css';
import CorseDettaglioImg from '../Images/CorseDettaglioImg.jpg'
import Footer from './Footer';
import L from 'leaflet';
import { Map, Marker, TileLayer, Tooltip, Polyline } from 'react-leaflet';
import Topbar from '../Utils/TopBar';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';


export default class DettaglioCorsa extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      travel: this.props.travel,
      dialogOpen: false,
      validDays: [],
      snackbarMessaggiOpen: false,
      vehicleStatus: {},
      noTrackingMessage:false,
      fullcoords : []
    }
  }

  componentDidMount() {
    this.getHolidays();
    this.loadFullCoords()
  }

  loadFullCoords() {
    //20230405 carico il percorso a partire dalle coordinare per disegnarlo sulla mappa
    let fullcoords = [];
    let coords = this.getCordinates();
    if(coords.length>1)
    fetchWay(coords).then(r => {
      if(r.error == undefined && r.result.code !=  "InvalidUrl" && r.result.routes != undefined){
        //fullcoords = r.result.routes[0].geometry.coordinates;
   
        r.result.routes[0].geometry.coordinates.forEach(c=>{
                       fullcoords.push({lat:c[1],lng:c[0]}) 

        })
  } else{
    fullcoords = coords;
  }      

    }).then(f => {
    
    this.setState({
      fullcoords:fullcoords
    })
    this.state.loadingWays = false;
    });
  }
  
  getHolidays() {
    return fetchData('calendarperiods')
      .then((responseJson) => {
        this.state.travel.holidayPeriods = responseJson.holidays
        this.state.travel.schoolPeriods = responseJson.schoolPeriods
      })
      .then(x => {
        this.getValidDays(this.state.travel);
      })
      .then(x => {
        this.setState({
          isLoading: false
        })
        this.getMessage()
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addAtStop(durf, event, timestamp) {
    for (let i = 0; i < this.props.travel.stops.length; i++) {
      if (this.props.travel.stops[i].durfCode == durf) {
        this.props.travel.stops[i].event = event;
        this.props.travel.stops[i].timestamp = timestamp;
        this.props.travel.stops[i].timestampDecoded = timestampToDate(timestamp);
        return;
      }
    }
  }


  getMessage() {
    //Verifico la presenza di messaggi relativi alla fermat
    if (this.state.travel.message != null) {
      //alert(this.state.travel.message);
      if(this.state.travel.messageExpire==null || new Date(this.state.travel.messageExpire) > new Date())
      this.setState({
        snackbarMessaggiOpen: true
      })
    }
  }

  getWarningMessage(){
    let messaggio="";
    let travel=this.state.travel;
    if (travel.message != null) {
      //alert(this.state.travel.message);
      if(travel.messageExpire==null || new Date(travel.messageExpire) > new Date()){
        if(travel.messageExpire!=null){
          return <div style={{textAlign:'center'}}><Warning style={{color:'orange'}}/> Corsa sospesa fino al {yyyyMMddToddMMyyyy(travel.messageExpire)}<Warning style={{color:'orange'}}/> <br></br> {travel.message} </div>
        } else return <div style={{textAlign:'center'}} ><Warning style={{color:'orange'}}/> Corsa sospesa <Warning style={{color:'orange'}}/> <br></br> {travel.message}</div>

    }
  }
  return messaggio
}

  isTodayInWorkingAndValidPeriods() {
    let today = formatDate(new Date());
    let disabled=this.state.validDaysString.includes(today.date);
    if(this.state.travel.message!=null){
      if(this.state.travel.messageExpire==null || new Date(this.state.travel.messageExpire) > new Date()){
        disabled=false;
      }     
    }
    return disabled;
  }
  fetchFromOldServer() {
    fetchFromOldService("vehiclestatus/bytravellegacycode/" + this.props.travel.legacyCode)
      .then((responseJson) => {

        if (responseJson.error != undefined) {
          this.setState({ isLoading: false, error: true });
          return;
        }
        return responseJson

      })
  }


  caricaVehicleStatus() {
    this.setState({ isLoading: true });
    //verifico se la data odierna è presente nelle date di attività della corsa        
    if (!this.isTodayInWorkingAndValidPeriods()) {
      this.setState({
        noTrackingMessage:true
      })
      this.setState({ isLoading: false });
    } else {
      //ricerco i pacchetti sul nuovo CORE
      fetchData('vehiclestatus/bytravellegacycode/' + this.props.travel.legacyCode)
        .then((responseJson) => {
          if (responseJson.error != undefined) {
            this.setState({ isLoading: false, error: true });
            return;
          }                 
          return responseJson;                    
        })/*.then((vehicleStatus) => {          
          //Chiamo il vecchio servizio CORe calabria, se ho già dei pacchetti restituisce quelli senza invocare il servizio
          fetchFromOldService('vehiclestatus/bytravellegacycode/' + this.props.travel.legacyCode,vehicleStatus)
            .then((responseJson) => {                                                 
               {                                   
               // if(responseJson.length == 0)
                 {                  
                  if (responseJson.error != undefined) {
                      this.setState({ error: true });
                          return;
                      }
                      this.setState({
                        isLoading: false,
                        noTrackingMessage:true
                  });                                   
                }
                vehicleStatus = responseJson;                 
              }              
              return vehicleStatus;                                         
            })*/
          .then((vehicleStatus) => {                   
            if (this.state.error || vehicleStatus.length == 0) return;            
            if(vehicleStatus && vehicleStatus.length == 0){
                  this.setState({
                    isLoading: false
                  })
                }
            this.setState({ durfs: 0 }); 
                let departureStopTime = this.state.travel.stops[0];
                let arrivalStopTime = this.state.travel.stops[this.state.travel.stops.length-1]; 

            vehicleStatus[0].packets = vehicleStatus[0].packets.filter(p => isInHoursBeforeDeparture(p.packet.timestamp,stopTimeToTodayDate(this.state.travel.stops[0].time, departureStopTime.time,arrivalStopTime.time).getTime(),ingorePacketsOlderThan))

            vehicleStatus[0].packets.forEach(p => {   
         
              // if (!isInHoursBeforeDeparture(p.packet.timestamp,stopTimeToTodayDate(this.state.travel.stops[0].time).getTime(),2)) 
              // return;
              
              if ( (!p.packet.parameter || p.packet.parameter == null || p.packet.parameter == 0)) {                    
                //non ho il durf nel pacchetto quindi lo recupero in base alla posizione presente nel pacchetto
                //fetchData('stops/nearto/' + p.packet.location.latitude + '/' + p.packet.location.longitude + '/0.075/10')                
               getNearestDurfs(p.packet.location.latitude,p.packet.location.longitude,this.state.travel,0.3)  
                .then((response) => {      
                  //debugger;                  
                        this.state.durfs++;
                        if (response.error != undefined) {
                          this.setState({ isLoading: false, error: true });
                          return;
                        }
                        this.setState({
                          error: false
                        });
                        //dei durf trovati prendo solo quelli che sono presenti nell'elenco delle fermate della corsa                                                            
                        let filtered = response.length == 0 ? [] : this.state.travel.stops.filter(s => response.filter(f => s.durfCode == f.durfCode).length > 0);                    
                        p.packet.parameter = filtered.length > 0 ? filtered[0].durfCode : null;                        
                      })                      
                  } else {
                    //dato che potrebbero aver inserito il parameter su un'altra posizione, inserisco nel pacchetto la location presa dalla fermata corrispondente
                    let stops = this.state.travel.stops.filter(s => s.durfCode == p.packet.parameter);
                    if(stops.length > 0) {
                      p.packet.location.latitude = stops[0].latitude;
                      p.packet.location.longitude = stops[0].longitude;
                    }                    
                    this.state.durfs++;
                  }
                })
            return vehicleStatus[0];
              })
          .then((vehicleStatus) => {            
            if (this.state.error || !vehicleStatus) {
            this.props.history.push('/Corse/VeicoliInMovimento',{vehicleStatus:null,travel:this.state.travel})
            return
          }
              let interval = setInterval(() => {
              if (!this.state.error && vehicleStatus.packets.length == this.state.durfs) {
                vehicleStatus.packets.forEach(p => {                  
                      this.addAtStop(p.packet.parameter, p.packet.trackingInfo.event, p.packet.timestamp);
                    });
                    this.setState({
                      isLoading: false
                    })
                     clearInterval(interval);                    
                    this.props.history.push('/Corse/VeicoliInMovimento', { vehicleStatus: vehicleStatus, travel: this.state.travel })
              }
                }, 500);
              })
        //})
        .catch((error) => {
          console.error(error);
        });
    }
  }
  periodsToString(periods) {
    let s = '';
    for (let i = 0; i < periods.length; i++) {
      let startDecoded = yyyyMMddToddMMyyyy(periods[i].startDate);
      let endDecoded = yyyyMMddToddMMyyyy(periods[i].endDate);
      s += startDecoded + ' - ' + endDecoded + ', ';
    }
    return s.substring(0, s.length - 2);
  }

  getValidDays(travel) {
    let validDaysString = [];
    let datesList = [];
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 60)//tolgo qualche giorno
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 365)
    while (startDate <= endDate) {
      let previusYearDate = new Date(startDate)
      previusYearDate.setDate(previusYearDate.getDate() - 365)//tolgo qualche giorno
      if (
        travel.periodicity.workingPeriods && (this.isInPeriods(startDate, travel.periodicity.workingPeriods, true) || this.isInPeriods(previusYearDate, travel.periodicity.workingPeriods, true))
        &&
        ((Array.isArray(travel.validPeriods) && travel.validPeriods.length > 0 && this.isInPeriods(startDate, travel.validPeriods)) || Array.isArray(travel.validPeriods) && travel.validPeriods.length == 0)
        &&
        ((Array.isArray(travel.notValidPeriods) && travel.notValidPeriods.length == 0) || (Array.isArray(travel.notValidPeriods) && travel.notValidPeriods.length > 0 && !this.isInPeriods(startDate, travel.notValidPeriods)))
        &&
        (travel.periodicity.notWorkingPeriods == (null || undefined) || (Array.isArray(travel.periodicity.notWorkingPeriods) && travel.periodicity.notWorkingPeriods.length == 0) || (Array.isArray(travel.periodicity.notWorkingPeriods) && travel.periodicity.notWorkingPeriods.length > 0 && !this.isInPeriods(startDate, travel.periodicity.notWorkingPeriods, true)))
      ) {
        if (this.isInWeekDays(startDate, travel.periodicity.operatingProfile)
          &&
          ((travel.periodicity.type !== "holiday" && !this.isInPeriods(startDate, travel.holidayPeriods))
            ||
            (travel.periodicity.type === "holiday" && (this.isInSunday(startDate, travel.periodicity.operatingProfile) || this.isInPeriods(startDate, travel.holidayPeriods))))
          &&
          ((travel.periodicity.type === "school" && this.isInPeriods(startDate, travel.schoolPeriods)) || travel.periodicity.type !== "school")
        ) {
          
          datesList.push(new Date(startDate));

          let dateAsString=startDate.toISOString().substr(0, 10)
          validDaysString.push(dateAsString)
        }
      }
      startDate.setDate(startDate.getDate() + 1)
    }
    this.setState({ validDays: datesList, validDaysString : validDaysString })
  }


  isInWeekDays(startDate, operatingProfile) {
    let arr = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    if (operatingProfile[arr[startDate.getDay()]]) {
      return true
    }
    else {
      return false
    }
  }

  isInSunday(startDate, operatingProfile) {
    let arr = ["sunday"]
    if (operatingProfile[arr[startDate.getDay()]]) {
      return true
    }
    else {
      return false
    }
  }


  isInPeriods(dateToCheck, periods, useCurrentYear) {      
    let isPresent = false
    for (let wp in periods) {
      let supLimit = new Date(periods[wp].endDate)
      let bottomLimit = new Date(periods[wp].startDate);
      //utilizzo l'anno corrente se sto analizzando una periodicità
      if(useCurrentYear != undefined && useCurrentYear) {
        supLimit.setFullYear(dateToCheck.getFullYear());
        bottomLimit.setFullYear(dateToCheck.getFullYear());
      }  
      
      supLimit.setDate(supLimit.getDate() + 1)
      if (new Date(dateToCheck).getTime() <= supLimit.getTime() && new Date(dateToCheck).getTime() >= bottomLimit.getTime()) {
        isPresent = true
        break;
      }
    }
    return isPresent

  }

  handleDialogOpen() {
    this.setState({
      dialogOpen: true
    })
  }

  handleDialogClose() {
    this.setState({
      dialogOpen: false
    })
  }

  getCordinates() {
    let coordinates = []
    this.props.travel.stops.forEach((s) => {
      coordinates.push({ lat: s.latitude, lng: s.longitude })
    })
    return coordinates
  }


  getPeriodicityView() {
    if (this.state.travel.periodicity) {
      return (
        <Typography>
          {/* <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.monday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>LU</span>
          <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.tuesday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>MA</span>
          <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.wednesday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>ME</span>
          <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.thursday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>GI</span>
          <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.friday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>VE</span>
          <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.saturday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>SA</span>
          <span style={{ backgroundColor: this.state.travel.periodicity.operatingProfile.sunday ? 'green' : 'red', margin: 3, borderRadius: 5, padding: 2 }}>DO</span> */}
        </Typography>
      )
    } else {
      return (
        <div>
          <ErrorView />
        </div>
      )
    }
  }

  render() {
    const pointerIcon = new L.Icon({
      iconUrl: require('../Images/leafletMarkers/marker-icon-red.png'),
      iconRetinaUrl: require('../Images/leafletMarkers/marker-icon-red.png'),
      iconAnchor: [12.5, 40],
      popupAnchor: [10, -44],
      iconSize: [25, 40],
      shadowUrl: 'leaflet/dist/images/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
     
    const p1=L.latLng(  37.522050, 14.767147);
    const p2=L.latLng(40.505361, 17.903747);
    const mapBounds = L.latLngBounds(p2, p1);
    const classes = {
      error: {
        backgroundColor: "#d32f2f",
      },
      messaggio: {
        backgroundColor: '#1976d2'
      },
      icon: {
        size: 50,
        margin: 10,
        marginLeft: 15,
        marginRight: 15,
      },
      iconVariant: {
        opacity: 0.9,
        marginRight: 10
      },
      message: {
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center'
      },

    };

    const birthdayStyle = `
        .DayPicker-Day:not(.DayPicker-Day--outside){
          background-color: red!important;
          color: white;
          padding:10;
          margin:5;
        }
        .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
          background-color: green!important;
          padding:10;
          margin:5;
        }
        `;
    if (this.state.isLoading) {
      return (
        <div><LoadingScreen /></div>
      )
    } else {
      const modifiers = {
        validDays: this.state.validDays
      }

      return (
        <div>
          <Topbar history={this.props.history} />
          <div style={{ backgroundSize: 'cover', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', height: '20vh', width: '100vw', backgroundImage: "url(" + CorseDettaglioImg + ")", minHeight: 280 }}></div>
          <br></br>
          {this.props.history.location.pathname == "/Corse/PianificaViaggioCorsa"?
          <Button style={{margin:10}} onClick={() => { window.history.back() }}>
            Torna all'itinerario
          </Button>:
          <Paper elevation={0} >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Button onClick={() => { this.props.history.push('/') }}>
                Home Page
                        </Button>
              <Button onClick={() => { this.props.history.location.pathname == "/Corse/CorseDettaglioLA" || this.props.history.location.pathname == "/Corse/CorseDettaglioLR" ? this.props.history.push('/Linee/LineeElenco') : this.props.history.push('/Fermate/FermateElenco') }}>
                {this.props.history.location.pathname == "/Corse/CorseDettaglioLA" || this.props.history.location.pathname == "/Corse/CorseDettaglioLR" ? 'Elenco Linee' : 'Elenco Fermate'}
              </Button>
              {this.props.history.location.pathname == "/Corse/CorseDettaglioAndata" || this.props.history.location.pathname == "/Corse/CorseDettaglioRitorno" ?
                <Button onClick={() => { window.history.go(-2) }}>
                  Dettaglio Fermata
                        </Button> : null}
              <Button onClick={() => { window.history.back() }}>
                Dettaglio Linea
                        </Button>
              <Typography color="grey">DETTAGLIO CORSA</Typography>
            </Breadcrumbs>
          </Paper>}
          <br></br>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            {this.getPeriodicityView()}
            <Typography style={{ fontSize: 20, margin: 10 }}>Informazioni sulla corsa {this.state.travel.code} {this.state.travel.legacyCode}</Typography>
            <Typography>{this.state.travel.operatorName}</Typography>
            <Typography style={{ fontSize: 20, margin: 10, color:'red' }}>{this.getWarningMessage()}</Typography>
            <Typography style={{ fontSize: 18, margin: 6 }}>
                <Button
                  onClick={() => {                  
                    this.handleDialogOpen()
                  }}
                  color="primary"
                  style={{ margin: 5 }}
                  variant="contained">
                  Visualizza calendario
                </Button>
            </Typography>
            <Typography style={{ fontSize: 18, margin: 6 }}>
              <Button
                onClick={() => {
                  this.caricaVehicleStatus()
                }}
                color="primary"
                style={{ margin: 5 }}
                variant="contained"
                disabled = {!this.isTodayInWorkingAndValidPeriods()}>
                In tempo reale
              </Button>

            </Typography>
            <Dialog disableBackdropClick={true} onClose={this.handleDialogClose} aria-labelledby="customized-dialog-title" open={this.state.dialogOpen}>
              <DialogTitle id="customized-dialog-title">
                Calendario esercizio
                  <IconButton aria-label="close" edge={"end"} onClick={() => {
                this.handleDialogClose()
                }}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogActions>
                <style>{birthdayStyle}</style>
                <DayPicker selectedDays={modifiers.validDays} weekdaysLong={['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato']} weekdaysShort={['Do','Lu','Ma','Me','Gi','Ve','Sa']} months={['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre',]} />
              </DialogActions>
            </Dialog>
            <div style={{width:'70vw'}}>
              <Table size="medium" aria-label="a dense table" style={{widt:'75vw'}}>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Indirizzo</TableCell>
                  <TableCell align="center">Orario</TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                    {this.state.travel.stops.map((stop, key) => {
                      return (
                        <TableRow>
                        {/* <p style={{ fontSize: 18, margin: 4, alignSelf: window.innerWidth > 500 ? 'center' : 'flex-start', flexDirection: 'row' }}>{key + 1}) <span style={{ color: '#F71912' }}>{stop.time}</span> - {stop.address}</p> */}
                          <TableCell align="center" style={{width:'5vw'}}>
                            {key+1}
                          </TableCell>
                          <TableCell align="center" style={{width:'25vw'}}>
                           {stop.address}  
                          </TableCell>
                          <TableCell align="center" style={{color:'red',width:'5vw'}}>
                           {stop.time}
                          </TableCell>
                        </TableRow>
                      )
                    })
                    }
                </TableBody>
              </Table>
              </div>
          </div>
          <Snackbar
            id="snackbar-messaggi"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.snackbarMessaggiOpen}
            autoHideDuration={5000}
            onClose={() => {
              this.setState({
                snackbarMessaggiOpen: false
              })
            }}
          >
            <SnackbarContent
              style={classes.messaggio}
              aria-describedby="client-snackbar"
              message={
                <span style={classes.message}>
                  <ErrorIcon style={classes.icon} />
                  Attenzione sono presenti degli avvertimenti sulla corsa</span>
              }
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={() => {
                  this.setState({
                    snackbarMessaggiOpen: false
                  })
                }}>
                  <CloseIcon className={classes.icon, classes.iconVariant} />
                </IconButton>,
              ]}
            />
          </Snackbar>
          <Snackbar
            id="snackbar-alert"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.noTrackingMessage}
            autoHideDuration={5000}
            onClose={() => {
              this.setState({
                snackbarOpen: false
              })
            }}
          >
            <SnackbarContent
              style={classes.error}
              aria-describedby="client-snackbar"
              message={
                <span style={classes.message}>
                  <ErrorIcon style={classes.icon} />
                  {"Nessun dato in tempo reale sulla corsa selezionata"}
                </span>
              }
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={() => {
                  this.setState({
                    noTrackingMessage: false
                  })
                }}>
                  <CloseIcon className={classes.icon, classes.iconVariant} />
                </IconButton>,
              ]}
            />
          </Snackbar>
          <Map style={{ height: "80vh" }} center={[this.props.travel.stops[0].latitude, this.props.travel.stops[0].longitude]} maxBounds={mapBounds} zoom={14}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {this.state.travel.stops ? this.state.travel.stops.map(
              (stop, key) => {
                return (
                  <Marker icon={pointerIcon} position={[stop.latitude, stop.longitude]}>
                    <Tooltip>{"Posizione numero "}{key + 1} </Tooltip>
                  </Marker>
                )
              }) : null}

                <Polyline 
                    positions={this.state.fullcoords}
                    color={"blue"}
                    stroke={true}                                              
                />
          </Map>
          <br></br>
          <Footer />
        </div>
      )
    }
  }
}