import React from 'react';
import { Slide } from 'react-slideshow-image';
import slide_4 from './Images/slide_4.jpg';
import slide_2 from './Images/slide_2.jpg';
import slide_3 from './Images/slide_3.jpg';
import Topbar from './Utils/TopBar';
import Footer from './Utils/Footer';
import CookieComponent from './Utils/CookieComponent';
import "es6-promise/auto";
import {fetchNews} from './Utils/network';
import ReactHtmlParser from 'react-html-parser';
import ButtonBases from './ButtonBases';
import LoadingScreen from './Utils/LoadingScreen';
import {Divider,Typography} from '@material-ui/core';
 
   
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    news: [],
    isLoading: true,
    error: false , 
    onChange: (oldIndex, newIndex) => {
    }
  }
  
  

export default class MenuCompleto extends React.Component {


    constructor(){
        super()
        this.state={
            news: [],
            isLoading: false,
            error: false  
        }
    }


 loadNews() {
        this.setState({isLoading : true});
        let dataOdierna = new Date();
        //let dataStringa = dataOdierna.getFullYear() + '-' + (dataOdierna.getMonth()+1) + '-' + dataOdierna.getDate();
        return fetchNews('display-date/' + dataOdierna.getTime() + '/status/0/max/9999')
          .then((responseJson) => {  
            if(responseJson.error != undefined){
              this.setState({
                isLoading:false,
                error:true,
              })
              return
            }
            console.log('RESULT', responseJson.result.map(e => e.title))
            let s = responseJson.result;

            //20240220 : ESCLUSIONE LINK DOWNLOAD APK ANDROID
            s.forEach(e => {
              if(e.title == 'Download APK') {
                e.content = e.content.replace('<p style=\"text-align: center;\">\n\t\t-</p>\n\t<h3 style=\"text-align: center;\">\n\t\t<a href=\"https://mobilita.regione.calabria.it/download/core.apk\">Download Android APK</a></h3>', '')
              }
            })
                      
            this.setState({
              isLoading: false,
              news: s,           
              error: false,
            }) 
            
                  
          })
          .catch((error) =>{
            console.error(error);
            this.setState({
              isLoading: false,
              error:true
            })
          });
      }

    componentDidMount(){
        require('es6-promise').polyfill();
        this.loadNews();
    }


    render() {
 if(this.state.isLoading==true){
            return(
                <div>
                    <LoadingScreen/>
                </div>
            )
        } else return (
            <div class="home" style={{flexDirection:'row',alignContent:'center',justifyContent:'center'}}>
                <Topbar history={this.props.history}/>
                <div style={{height:'auto',minHeight:'99vh'}}>
                    <Slide {...properties}>
                        <div className="each-slide">
                            <img style={{width:'100%',height:'auto'}}src={slide_4}/>                           
                        </div>
                        <div className="each-slide">
                            <img style={{width:'100%',height:'auto'}}src={slide_2}/> 
                        </div>
                        <div className="each-slide">
                            <img style={{width:'100%',height:'auto'}}src={slide_3}/> 
                        </div>
                    </Slide>                
                  <br></br>
                  <ButtonBases history={this.props.history} />
                  
                  {this.state.news.map((avviso)=>{
                          return(
                            avviso.description.includes('#home')?
                            <div class="liferay-article ">
                              <br/>
                              <b class="title">{ReactHtmlParser(avviso.title)}</b>
                                {ReactHtmlParser(avviso.content)}
                              <br/>
                              <Divider/>
                            </div>:null
                          )
                        })}
                  </div>
                  <CookieComponent/>
                  <br></br>
                  <Footer />  
            </div>   
        );
    
    }
}



const classes = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },

  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.4,
  },
  imageTitle: {
    position: 'relative',
  },
  imageMarked: {
    height: 3,
    width: 18,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
  },
};


